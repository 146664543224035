import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const REDIRECT_URL = 'https://fresh-trout-alive.ngrok-free.app/jasperserver';

const App = () => {

  useEffect(() => {
    // (async () => {
    //   try {
    //     const headers = {
    //       'ngrok-skip-browser-warning': 1,
    //     };

    //     const response = await axios.get(REDIRECT_URL, { headers });
    //     console.log('API Response:', response.data);

    //   } catch (err) {
    //     console.error('API Error:', err);
    //   }
    // })();


    const timer = setTimeout(() => {
      // Redirect after successful API call
      window.location.href = REDIRECT_URL;
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 flex-col pb-72">
      <motion.img
        src="https://easydeploy.andyamaya.com/wp-content/uploads/2016/06/Untitled-1.png" // Replace with your actual image URL
        alt="Andy Amaya I.T. Services"
        className="w-32 h-32 object-cover"
        animate={{
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />
      <br />
      <p className="text-xl">Andy Amaya I.T. Services</p>
      <br />
      <p className="pt-16 inline-block overflow-hidden whitespace-nowrap animate-typing text-2xl text-black">
        Redirecting to your application...
      </p>
    </div>
  );
};

export default App;